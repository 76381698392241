import React from 'react';
import TermsWoh from './TermsWoh';
import TermsBeauty from './TermsBeauty';
import TermsBoss from './TermsBoss';
import Login from './Login'

const TermsConditions = () => {
  const hostname = window.location.hostname;
  console.log("hostname",hostname)
  const subdomain = window.location.hostname.split('.')[0];
  console.log("subdomain",subdomain)

  let componentToRender;
    
  switch (subdomain) {
    case 'bossmoves':
      componentToRender = <TermsBoss />;
      break;
    case 'beauty':
      componentToRender = <TermsBeauty />;
      break;
    case 'woh':
      componentToRender = <TermsWoh />;
      break;
    default:
      componentToRender = <Login/>; 
  }

  return <div>{componentToRender}</div>;
};

export default TermsConditions;
