import React from 'react'

const TermsBoss = () => {
  return (
    <div className='container px-5 w-full mx-auto flex justify-center mt-10'>
      
    <a  class="block p-6 bg-white border mb-5 border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
    
    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 text-center dark:text-white">Boss Moves Terms and Conditions</h5>
    {/* <p class="font-semibold  text-black  py-2">Effective Date: 01 December 2023</p> */}
    <p class="font-normal text-gray-700 dark:text-gray-400">These Terms and Conditions have been varied or amended as of 01 December 2023.   </p>
    
    
    <p>
      <div className=' px-5 mt-5'>
      <span className='text-black py-3 font-bold text-xl'>NOTE:</span>
    
    <p className='py-3'><b>A. </b>The following Terms and Conditions contain assumptions of risk and/or liability by you and limit and exclude liabilities, obligations, and legal responsibilities which Mobile Telephone Networks (Proprietary) Limited ("MTN") and MSR Innovations NG (Proprietary) Limited ("MSR") together referred to as the ("Content Provider") will have towards you and other persons. These Terms and Conditions also limit and exclude your rights and remedies against the content provider and place various risks, liabilities, obligations, and legal responsibilities on you. These Terms and Conditions may result in you being responsible for paying additional costs and amounts and the content provider may also have claims and other rights against you</p>
    
    <p className='py-3'>
    <b>B.</b>To the extent that the Terms and Conditions or any goods or services provided under the Terms and Conditions are governed by the Consumer Protection Act, 2008 (the "Consumer Protection Act"), no provision of the Terms and Conditions are intended to contravene the applicable provisions of the Consumer Protection Act, and therefore all provisions of the Terms and Conditions must be treated as being qualified, to the extent necessary, to ensure that the applicable provisions of the Consumer Protection Act are complied with.
    </p>
    
    <p className='py-3'>
    
      <b>C. </b>
      Please read these Terms and Conditions carefully. Participation in this offer will constitute your agreement to comply with these Terms and Conditions. If you do not agree with these Terms and Conditions, please do not participate in this offer.
    
     </p>
    
     SECTION 49 CONSUMER PROTECTION ACT NO 68 OF 2008 NOTICE, THE FOLLOWING TERMS ARE IMPORTANT TO NOTE:  CLAUSES 2,3,4 AND 5
      </div>
    
    </p>
    
    <div className=' mt-5'>
      <p className='text-black text-xl font-bold'> 1.Introduction</p>
     
      <ul className='px-5 mt-5'>
        <li>1.	The Education Video is a one-stop channel to unlock your potential in the professional world. It is used to learn strategies to enhance your marketability in the job market, refine CV to stand out, and explore avenues to pursue studies abroad. It has a variety of videos for subscribers to enjoy.</li>
    
    
      </ul>
     <p className='text-black  mt-5 px-3 text-xl font-bold'>Duration</p> 
     <ul className='px-5 py-3'>
      <li>1.1		The Service is available from December 2023 until the content provider in their sole and absolute discretion decides to discontinue the Service.</li>
     </ul>
    </div>
    
    <div className='mt-5'>
      <p  className='text-black text-xl font-bold'>2.	Who is Eligible?</p>
      <p className='mt-3 px-5 '>2.1.Service is available to all MTN Nigeria customers with a valid mobile number (“Subscriber/s”).</p>
    </div>
    
    <div className='mt-5'>
      <p className='text-black text-xl font-bold'>3.	Subscribing </p>
      <p className='mt-3 px-5 '>3.1	Subscribers should subscribe to the Service by SMS or Via the Web; For SMS subscribers simply text:  </p>
      <ul className='px-6 mt-3' >
        <li>a) NE1 to 4800 at N50 daily.</li>
        <li>b) NE2 to 4800 at N200 weekly</li>
        <li>c) NE3 to 4800 at N500 Monthly</li>
    
      </ul>
    </div>
    
    
    <div className='mt-5'>
      <p className='text-black text-xl font-bold'>4	UnSubscribing </p>
      <p className='mt-3 px-5 '>4.1	Subscribers can unsubscribe via SMS, users should simply text:  </p>
      <ul className='px-6 mt-3'>
        <li>a) STOP NE1 to 4800 for the daily subscription.</li>
        <li>b) STOP NE2 to 4800 for the weekly subscription</li>
        <li>c) STOP NE3 to 4800 for the Monthly subscription</li>
    
    
      </ul>
      <p className='mt-3 px-5 '>4.2 Players will receive an SMS confirming that they have been unsubscribed from the service.  </p>
      <p className='mt-3 px-5 '>4.3 If they unsubscribe from the service, they will not be eligible to enjoy the service.</p>
    </div>
    
    <div className='mt-5 mb-5'>
      <p className='text-black text-xl font-bold'>
      5.	General
      </p>
    
    <p  className='px-5 mt-3'>5.1 The Service begins immediately after the user subscribes

.</p>
    <p className='px-5'>5.2	MTN reserves the right, at any time, to change these Terms and Conditions and any rules relating to its products and the Service, and to modify the Services at its discretion.

</p>
    <p className='px-5'>5.3	MTN reserves the right from time to time to vary the charges of the Service as may be deemed necessary in its discretion. </p>
    <p className='px-5'>5.4	MTN shall be entitled to terminate the Service of any Customer, if such Customer is involved in any fraudulent activity or suspected fraudulent activity.</p>
    <p className='px-5'>5.5	Subscribers can opt out of the service at  anytime.</p>
    </div>
    </a>
    
        </div>
  )
}

export default TermsBoss
